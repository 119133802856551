import ValuePropsII from "./ValuePropsII";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import formatPrismicData from "../../hocs/formatPrismicData";

const buildIcon = (icon) => {
  const dict = {
    bear: {
      icon: {
        name: "bearface",
        color: "#2CB492",
        backgroundColor: "#E9F7F4",
      },
    },
    "delivery-van": {
      icon: {
        name: "deliveryvan",
        color: "#00549A",
        backgroundColor: "#E5EDF4",
      },
    },
    clock: {
      icon: {
        name: "clock",
        color: "#F6707B",
        backgroundColor: "#F6707B",
      },
    },
    heart: {
      icon: {
        name: "heart",
        color: "#F6707B",
        backgroundColor: "#FEF0F1",
      },
    },
    "heart-book": {
      icon: {
        name: "heartbook",
        color: "#F6707B",
        backgroundColor: "#FEF0F1",
      },
    },
    "message-bubble": {
      icon: {
        name: "chat",
        color: "#645B9B",
        backgroundColor: "#EFEEF5",
      },
    },
    "paper-plane": {
      icon: {
        name: "send",
        color: "#00549A",
        backgroundColor: "#E5EDF4",
      },
    },
    pencil: {
      icon: {
        name: "write",
        color: "#F48D61",
        backgroundColor: "#FDF3EF",
      },
    },
    present: {
      icon: {
        name: "gift",
        color: "#645B9B",
        backgroundColor: "#EFEEF5",
      },
    },
    star: {
      icon: {
        name: "star",
        color: "#29b47c",
        backgroundColor: "#E9F7F4",
      },
    },
    "thumbs-up": {
      icon: {
        name: "like",
        color: "#00549A",
        backgroundColor: "#E5EDF4",
      },
    },
  };
  return dict[icon] || null;
};

const enhance = compose(
  formatPrismicData,
  withProps((props) => ({
    items: props.items.map(({ icon, description, ...item }) => ({
      ...item,
      ...buildIcon(icon),
      description: [
        {
          type: "paragraph",
          text: description,
          spans: [],
        },
      ],
    })),
  }))
);

export default enhance(ValuePropsII);
