import React from "react";
import PropTypes from "prop-types";
import has from "lodash/has";
import Icon from "wonderbly-components/lib/Icon";
import RichText from "../Prismic/RichText";
import SectionHeading from "src/client/js/view/components/SectionHeading";
import { prismicImage } from "src/common/utils/prismic-image";

import "./ValuePropsII.scss";

const ValuePropsII = (props) => (
  <section className="value-props-ii">
    <SectionHeading richText={props.title} textAlign="left" />
    <div className="container-guttered">
      <div className="value-props-ii-row">
        {props.items.map((item, idx) => (
          <div className="value-prop-ii" key={idx}>
            {!has(item, "image.url") ? (
              <div
                className="value-prop-ii-shape"
                style={{
                  color: item.icon.color,
                  backgroundColor: item.icon.backgroundColor,
                }}
              >
                <Icon icon={item.icon.name} />
              </div>
            ) : (
              <div className="value-prop-ii-shape">
                <img
                  loading="lazy"
                  className="value-prop-ii-image"
                  src={prismicImage(item.image)}
                  alt={item.image.alt || ""}
                />
              </div>
            )}
            <div className="value-prop-ii-content">
              <h4 className="value-prop-ii-title">{item.title}</h4>
              {item.description && <RichText fields={item.description} />}
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

ValuePropsII.propTypes = {
  title: PropTypes.array,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.array,
      icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
          backgroundColor: PropTypes.string.isRequired,
        }),
      ]),
      image: PropTypes.object,
    })
  ).isRequired,
};

export default ValuePropsII;
